/**
 * Component for displaying a video as background and fullscreen
 *
 * @see https://zeroheight.com/9057f5720/p/50a793/b/07307b
 *
 */
Vue.component("video-component", {
    mixins: [Mixins.video],
    template:
    '<div class="image-or-video__video video">' +
        '<video ref="video" autoplay playsinline loop muted ' +
            'class="video__player ratio__image" ' +
            ':class="{ \'video__player--fullscreen d-block\': fullscreen, ' +
            '\'video__player--no-fullscreen\': fullscreen }">' +
                '<source :src="url" type=\'video/mp4\' />' +
                    'Your browser does not support HTML5 video.' +
        '</video>' +
        '<img :data-src="poster" class="ratio__image b-lazy" alt="LuxImprove Video"/>' +
    '</div>'
});
