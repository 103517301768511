Mixins.datafilled = {
    data: function () {
        return {
            inputs: [],
        }
    },
    mounted() {
        this.dataFilled();
    },

    updated() {
        this.dataFilled();
    },
    methods: {
        dataFilled () {
            let that = this;
            this.inputs = this.$el.querySelectorAll('.form__input');

            for (let i = 0, input; input = this.inputs[i]; i++) {
                input.addEventListener('input', e => {
                    that.checkFilled(e.target);
                })

                this.checkFilled(input);
            }
        },
        checkFilled (elm) {
            const value = elm.value.trim();

            if (value) {
                elm.dataset.state = 'filled'
            } else {
                elm.dataset.state = ''
            }
        },
    },
}
